const TikTokIcon = () => {
    return (
        <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.1522 4.082C22.0025 3.98594 21.8529 3.98594 21.7032 4.03397C20.4562 4.5623 19.1594 4.17806 18.4112 3.21746C18.062 2.78519 17.8625 2.20883 17.8625 1.6805C17.8625 1.53641 17.8126 1.44035 17.7128 1.34429C17.6131 1.24823 17.5133 1.2002 17.3637 1.2002H12.2759C11.9766 1.2002 11.7771 1.39232 11.7771 1.6805V18.7312C11.7771 18.7792 11.7771 18.7792 11.7771 18.8272C11.6773 19.6917 10.979 20.3161 10.0812 20.3161C9.13346 20.3161 8.33538 19.5477 8.33538 18.6351C8.33538 17.7706 8.98382 17.0981 9.88166 17.0021C10.1311 16.954 10.3306 16.7619 10.3306 16.5218V10.9503C10.3306 10.6621 10.1311 10.47 9.83178 10.47C9.83178 10.47 9.7819 10.47 9.73202 10.47C5.14305 10.6621 1.60156 14.2163 1.60156 18.6351C1.60156 23.1499 5.39245 26.8002 10.0812 26.8002C14.4706 26.8002 18.1618 23.5342 18.5109 19.3555C18.5608 19.3075 18.5608 19.2115 18.5608 19.1634V9.89363C19.6582 10.1338 20.8054 10.2298 22.0025 10.0377C22.2519 9.98969 22.4016 9.79757 22.4016 9.55742V4.46624C22.4016 4.32215 22.3018 4.17806 22.1522 4.082ZM21.404 9.17318C20.2567 9.26924 19.2092 9.17318 18.1618 8.83697C18.0121 8.78894 17.8625 8.83697 17.7128 8.885C17.5632 8.98106 17.5133 9.12515 17.5133 9.26924V18.9713C17.5133 19.0193 17.5133 19.0674 17.4634 19.1154C17.214 22.8617 13.9219 25.8396 10.0313 25.8396C5.89125 25.8396 2.54928 22.6216 2.54928 18.6351C2.54928 14.8888 5.44233 11.8148 9.2831 11.4786V16.1375C8.13586 16.4737 7.28789 17.4824 7.28789 18.6351C7.28789 20.076 8.5349 21.2767 10.0313 21.2767C11.3781 21.2767 12.5253 20.3161 12.7248 19.0674C12.7747 19.0193 12.7747 18.9233 12.7747 18.8752V2.1608H16.9148C17.0145 2.78519 17.2639 3.31352 17.6131 3.79382C18.461 4.89851 19.9574 5.47487 21.404 5.13866V9.17318Z"
                fill="#5C5C5C"
                stroke="#5C5C5C"
                strokeWidth="1.27273"
            />
        </svg>
    );
};
export default TikTokIcon;
