import React from "react";
import dynamic from "next/dynamic";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import GTMLink from "../../Atoms/GTMLink";
import { GoogleTagManagerEvents } from "../../../common";

import {
    CONTAINER_SIZE_CLASS,
    MAIN_CONTAINER_CLASS,
    NEWSLETTER_SECTION_CONTAINER,
    slugify,
    SOCIAL_MEDIA_LINKS,
    _BUTTON_FOOTER_ID,
    NEWS_LETTER_HEADER_TEXT,
    NEWS_LETTER_FOOTER_TEXT,
    NEWSLETTER_HEADING_CLASS,
    NEWSLETTER_FOOTER_CLASS,
    PRIVACY_POLICY_LINK,
    TERMS_AND_CONDITIONS_LINK,
    POLICY_TERM_CLASS,
    COPYRIGHT_CLASS,
    POLICY_TERM_CONTAINER,
    FOOTER_COPYRIGHT_CLASS,
    PRIVICY_POLICY,
    TERMS_OF_USE,
    COPYRIGHT_TEXT,
    NEWSLETTER_SECTION_WRAPPER,
    FOOTER_LINKS,
    NEWS_LETTER_TERMS_CTA,
    NEWS_LETTER_TERMS_CTA_CLASS,
    NEWS_LETTER_TERMS_DOT,
} from "./constants";

const NewsletterForm2 = dynamic(() => import("../NewsletterForm2"));

const NewFooterSection = ({}) => {
    return (
        <footer className={MAIN_CONTAINER_CLASS}>
            <div className={CONTAINER_SIZE_CLASS}>
                <div className={NEWSLETTER_SECTION_WRAPPER}>
                    <div className={NEWSLETTER_SECTION_CONTAINER}>
                        <h2 className={NEWSLETTER_HEADING_CLASS}>
                            {NEWS_LETTER_HEADER_TEXT}
                        </h2>
                        <NewsletterForm2 buttonAgainstField />
                        <h2 className={NEWSLETTER_FOOTER_CLASS}>
                            {NEWS_LETTER_FOOTER_TEXT}
                            <a
                                className={NEWS_LETTER_TERMS_CTA_CLASS}
                                href={TERMS_AND_CONDITIONS_LINK}
                            >
                                {NEWS_LETTER_TERMS_CTA}
                            </a>
                            {NEWS_LETTER_TERMS_DOT}
                        </h2>
                    </div>
                </div>
                <div
                    className={
                        " grid grid-cols-2  my-4 md:grid-cols-4 xl:ml-28 xl:mr-20	"
                    }
                >
                    {FOOTER_LINKS?.map((footer, index) => (
                        <div
                            key={index}
                            className=" sm:ml-20 md:ml-0 lg:ml-14 xl:ml-10"
                        >
                            <TypographyMontserrat
                                text={footer?.section}
                                fontWeight={"light"}
                                size={"base"}
                                customStyle={
                                    " !leading-7 !text-base md:!text-xl md:!leading-9 !text-clip "
                                }
                            />
                            <ul className=" mt-3 mb-5 ">
                                {footer?.links?.map((link, index) => (
                                    <li key={link?.title + index}>
                                        <GTMLink
                                            event={
                                                GoogleTagManagerEvents.navigationInteractions
                                            }
                                            place={"footer"}
                                            menuName={"footer-menu"}
                                            prefetch
                                            href={link?.href || ""}
                                            className=" font-medium text-[13px] leading-4 md:text-sm font-Montserrat"
                                        >
                                            {link?.title}
                                        </GTMLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className={"flex justify-center items-center"}>
                    <div className="flex justify-between items-center h-8 w-52 sm:w-72 sm:h-10">
                        {SOCIAL_MEDIA_LINKS?.map((item, index) => (
                            <GTMLink
                                event={
                                    GoogleTagManagerEvents.navigationInteractions
                                }
                                place={"footer"}
                                menuName={"footer-menu"}
                                href={item?.link}
                                key={index}
                                target="_blank"
                                name={item?.id}
                                className={item?.className}
                            >
                                {item?.Icon}
                            </GTMLink>
                        ))}
                    </div>
                </div>
                <div className={COPYRIGHT_CLASS}>
                    <div className={POLICY_TERM_CONTAINER}>
                        <GTMLink
                            event={
                                GoogleTagManagerEvents.navigationInteractions
                            }
                            place={"footer"}
                            menuName={"footer-menu"}
                            prefetch
                            className={POLICY_TERM_CLASS}
                            href={PRIVACY_POLICY_LINK}
                        >
                            {PRIVICY_POLICY}
                        </GTMLink>
                        <GTMLink
                            event={
                                GoogleTagManagerEvents.navigationInteractions
                            }
                            place={"footer"}
                            menuName={"footer-menu"}
                            prefetch
                            className={POLICY_TERM_CLASS}
                            href={TERMS_AND_CONDITIONS_LINK}
                        >
                            {TERMS_OF_USE}
                        </GTMLink>
                    </div>
                    <p className={FOOTER_COPYRIGHT_CLASS}>{COPYRIGHT_TEXT}</p>
                </div>
            </div>
        </footer>
    );
};

export default NewFooterSection;
