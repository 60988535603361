// store.js
import { configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
// import storage from 'redux-persist/lib/storage'; // You can choose a different storage option if needed
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import preferencesReducer from "./preferencesSlice";
import boxOrderReducer from "./boxOrderSlice";
import surveySlice from "./surveySlice";
import homePopupReducer from "./homePopupSlice";
import countryReducer from "./countrySlice";
import paymentReducer from "./paymentSlice";

// Configuration for Redux persist

import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem(_key) {
            return Promise.resolve();
        },
    };
};

const storage =
    typeof window !== "undefined"
        ? createWebStorage("local")
        : createNoopStorage();

const persistConfig = {
    key: "root", // The key for the root of the persisted state
    storage: storage, // The storage option to be used for persisting the state
    // You can configure additional options here, such as blacklist/whitelist, transforms, etc.
    timeout: 100,
};
// Wrap the authReducer with persistReducer
const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedUserReducer = persistReducer(persistConfig, userReducer);
const persistedPopupReducer = persistReducer(persistConfig, homePopupReducer);
const persistedPreferencesReducer = persistReducer(
    persistConfig,
    preferencesReducer
);
const persistedBoxOrderReducer = persistReducer(persistConfig, boxOrderReducer);
const persistedSurveyReducer = persistReducer(persistConfig, surveySlice);
const persistedCountryReducer = persistReducer(persistConfig, countryReducer);

// Create the Redux store
const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        user: persistedUserReducer,
        // Use the persistedAuthReducer as the reducer for the 'auth' slice
        payment: paymentReducer,
        preferences: persistedPreferencesReducer,
        popup: persistedPopupReducer,
        // Use the persistedAuthReducer as the reducer for the 'auth' slice
        boxOrder: persistedBoxOrderReducer,
        survey: persistedSurveyReducer,
        country: persistedCountryReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
            immutableCheck: false,
        }),
});

// Create a persistor for persisting the Redux state
export const persistor = persistStore(store);

export default store;
