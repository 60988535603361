import Link from "next/link";
import dynamic from "next/dynamic";
import classNames from "classnames";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { SaudiIcon, UAEIcon } from "../../../public/Theme";
import Image from "next/image";
import { getCookie } from "cookies-next";
import { CloseIcon, NewMenuIcon, WTLogoEnglish } from "../../../public/Theme";
import CurrencySelectorDropdown from "../CurrencySelectorDropdown";
import {
    CLOSE_ICON_ALT,
    DRAWER_NAVIGATION,
    HEADER_BUTTON_ID,
    HEADER_LINK,
    HEADER_LOGIN_BUTTON_ID,
    HEADER_LOGO_IMAGE_ID,
    LAZY,
    LOGIN_ENDPOINT,
    LOGO_ALT_TEXT,
    MENU_ICON_ALT,
    slugify,
    COUNTRY_ICON_ALT,
} from "./constants";
import {
    CANCEL_ICON_CONTAINER,
    CLOSE_BUTTON_CLASS,
    DASHBOARD_DRAWER_CLOSE_TRANSITION,
    DASHBOARD_DRAWER_CONTAINER,
    DRAWER_BUTTON_CONTAINER,
    DRAWER_CLOSE_TRANSITION,
    DRAWER_CONTAINER,
    DRAWER_INNER_CONTAINER,
    DRAWER_OPEN_TRANSITION,
    DRAWER_PARENT_CONTAINER,
    DROPDOWN_CLASS,
    HEADER_LINK_CLASS,
    HEADER_UNORDERED_LIST_CLASS,
    LINKS_AND_BUTTONS_CONTAINER,
    LINKS_CONTAINER,
    LOGO_CLASS,
    LOGO_IMAGE_CLASS,
    MARGING_3,
    MENU_ICON_CLASS,
    MENU_IMAGE_CLASS,
    NAVBAR_CLASS,
    SPACE_Y_2,
    MX_AUTO,
    HEADER_CLASS,
} from "./styles";
import {
    BUTTON,
    LOGIN_TEXT,
    MINUS_ONE,
    MOBILE_SCREEN_BREAKPOINT,
    PADDING_LEFT_ZERO,
    WIDTH_113_PX,
    USER_COUNTRY,
    SAUDI_ISO,
} from "../../../public/Constants/EnumConstants";
import UserInitialsAvatar from "../UserInitialsAvatar";
import { DashboardSidebar } from "../DashboardSidebar";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import { usePathname } from "next/navigation";
import GTMLink from "../../Atoms/GTMLink";
import { GoogleTagManagerEvents } from "../../../common";

const NewPrimaryButton = dynamic(
    () => import("../../Atoms/Buttons/NewPrimaryButton")
);
const HeaderLink = dynamic(() => import("../../Atoms/Typography/HeaderLink"));
const HeaderLinkWithIcon = dynamic(
    () => import("../../Atoms/Typography/HeaderLinkWithIcon")
);

const NewHeaderLoginButton = dynamic(
    () => import("../../Atoms/Buttons/NewHeaderLoginButton")
);

const NewHeaderSection = ({ homeUrl }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [userInitials, setUserInitials] = useState(null);
    const [dashboardDrawerOpen, setDashboardDrawerOpen] = useState(false);
    const breakPoint = useMediaQuery(MOBILE_SCREEN_BREAKPOINT);
    const closeButtonClass = classNames(CLOSE_BUTTON_CLASS, "right-2.5");
    const { pathname } = usePathname();
    const { userData } = useSelector((state) => state.user);
    useEffect(() => {
        if (userData) {
            setUserInitials(
                `${userData?.user?.first_name[0]?.toUpperCase()}${userData?.user?.last_name[0]?.toUpperCase()}`
            );
        }
    }, [userData]);

    return (
        <header className={HEADER_CLASS}>
            <nav
                className={
                    drawerOpen ? PADDING_LEFT_ZERO + NAVBAR_CLASS : NAVBAR_CLASS
                }
            >
                <div className={LINKS_AND_BUTTONS_CONTAINER}>
                    <button
                        type={BUTTON}
                        onClick={() => {
                            setDrawerOpen(!drawerOpen);
                            setDashboardDrawerOpen(false);
                        }}
                        data-drawer-target={DRAWER_NAVIGATION}
                        data-drawer-show={DRAWER_NAVIGATION}
                        // aria-controls={DRAWER_NAVIGATION}
                        className={MENU_ICON_CLASS}
                    >
                        <Image
                            src={NewMenuIcon}
                            alt={MENU_ICON_ALT}
                            className={MENU_IMAGE_CLASS}
                            width={50}
                            height={50}
                        />
                    </button>
                    <GTMLink
                        text={"Home"}
                        href={homeUrl}
                        passHref
                        prefetch
                        replace={true}
                        className={LOGO_CLASS}
                        event={GoogleTagManagerEvents.navigationInteractions}
                        place="header"
                        menuName={"header navigation menu"}
                    >
                        <Image
                            width={100}
                            height={100}
                            src={WTLogoEnglish}
                            className={LOGO_IMAGE_CLASS}
                            alt={LOGO_ALT_TEXT}
                            id={HEADER_LOGO_IMAGE_ID}
                            data-cy={HEADER_LOGO_IMAGE_ID}
                        />
                    </GTMLink>
                    <div className={LINKS_CONTAINER}>
                        <ul className={HEADER_UNORDERED_LIST_CLASS}>
                            {HEADER_LINK?.map((item, index) => (
                                <li key={index} className={HEADER_LINK_CLASS}>
                                    <HeaderLink
                                        link={item?.link}
                                        text={item?.text}
                                        external={item?.external}
                                        headerId={slugify(
                                            HEADER_BUTTON_ID + `_${item?.text}`
                                        )}
                                    ></HeaderLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className={LINKS_AND_BUTTONS_CONTAINER}>
                    {!userData ? (
                        <NewHeaderLoginButton
                            text={LOGIN_TEXT}
                            href={LOGIN_ENDPOINT}
                            width={WIDTH_113_PX}
                            headerId={HEADER_LOGIN_BUTTON_ID}
                        ></NewHeaderLoginButton>
                    ) : (
                        <div
                            onClick={() => {
                                if (breakPoint) {
                                    setDashboardDrawerOpen(
                                        !dashboardDrawerOpen
                                    );
                                    setDrawerOpen(false);
                                }
                            }}
                            className={DROPDOWN_CLASS}
                        >
                            <UserInitialsAvatar
                                text={userInitials}
                                topBar
                                breakPoint={breakPoint}
                            />
                        </div>
                    )}
                    <div className="ml-4">
                        <CurrencySelectorDropdown />
                    </div>

                    {/* <div
            className={
              lang === ARABIC
                ? SWITCHER_LINKS_CONTAINER_ARABIC
                : SWITCHER_LINKS_CONTAINER
            }
          >
            <NewNotificationBellIcon count={2} />
          </div> */}
                </div>
            </nav>

            <div
                onClick={() => setDashboardDrawerOpen(!dashboardDrawerOpen)}
                className={
                    DRAWER_PARENT_CONTAINER +
                    (dashboardDrawerOpen && breakPoint
                        ? DRAWER_OPEN_TRANSITION
                        : DASHBOARD_DRAWER_CLOSE_TRANSITION)
                }
                tabIndex={MINUS_ONE}
                dir="ltr"
            >
                <div className={DASHBOARD_DRAWER_CONTAINER}>
                    <div
                        className={CANCEL_ICON_CONTAINER}
                        onClick={() =>
                            setDashboardDrawerOpen(!dashboardDrawerOpen)
                        }
                    >
                        <Image
                            src={CloseIcon}
                            width={25}
                            height={25}
                            alt="close-icon"
                        />
                    </div>
                    <DashboardSidebar
                        setDashboardDrawerOpen={setDashboardDrawerOpen}
                    />
                </div>
            </div>

            <div
                onClick={() => setDrawerOpen(!drawerOpen)}
                className={
                    DRAWER_PARENT_CONTAINER +
                    (drawerOpen
                        ? DRAWER_OPEN_TRANSITION
                        : DRAWER_CLOSE_TRANSITION)
                }
            >
                <div
                    id={DRAWER_NAVIGATION}
                    className={DRAWER_CONTAINER}
                    tabIndex={MINUS_ONE}
                >
                    <GTMLink
                        event={GoogleTagManagerEvents.navigationInteractions}
                        menuName={"header navigation menu"}
                        place={"header"}
                        href={homeUrl}
                        prefetch
                    >
                        <Image
                            width={100}
                            height={100}
                            src={WTLogoEnglish}
                            className={LOGO_IMAGE_CLASS + MX_AUTO}
                            alt={LOGO_ALT_TEXT}
                            loading={LAZY}
                        />
                    </GTMLink>
                    <button
                        type={BUTTON}
                        data-drawer-dismiss={DRAWER_NAVIGATION}
                        aria-controls={DRAWER_NAVIGATION}
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={closeButtonClass}
                    >
                        <Image
                            width={25}
                            height={25}
                            src={CloseIcon}
                            className={CANCEL_ICON_CONTAINER}
                            alt={CLOSE_ICON_ALT}
                            loading={LAZY}
                        />
                    </button>
                    <div className={DRAWER_INNER_CONTAINER}>
                        <ul className={SPACE_Y_2}>
                            {HEADER_LINK?.map((item, index) => (
                                <li key={index}>
                                    <HeaderLinkWithIcon
                                        text={item?.text}
                                        link={item?.link}
                                        src={item?.src}
                                        onClick={() =>
                                            setDrawerOpen(!drawerOpen)
                                        }
                                        startEndorment={true}
                                        margin={MARGING_3}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    {!userInitials && (
                        <div className={DRAWER_BUTTON_CONTAINER}>
                            <NewPrimaryButton
                                event={
                                    GoogleTagManagerEvents.navigationInteractions
                                }
                                place="header"
                                menuName={"header navigation menu"}
                                width=" !w-36 justify-center "
                                text={LOGIN_TEXT}
                                href={`${LOGIN_ENDPOINT}`}
                                onClick={() => setDrawerOpen(!drawerOpen)}
                            />
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default NewHeaderSection;
