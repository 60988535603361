import Link from "next/link";
import { useRouter } from "next/navigation";
import { sendGTMEvent } from "@next/third-parties/google";

const GTMLink = ({
    onClick,
    children,
    href,
    prefetch,
    replace,
    ariaHidden,
    id,
    dataCy,
    text,
    external,
    event,
    place,
    menuName,
    ...props
}) => {
    const router = useRouter();

    const handleClick = (e) => {
        if (!external) {
            e.preventDefault();
        }

        // Send GTM event
        sendGTMEvent({
            event: event,
            menu_name: menuName,
            place: place,
        });
        if (onClick) {
            onClick();
        }
        // Handle navigation for internal links
        if (!external) {
            router.push(href);
        }
    };

    return (
        <Link
            href={href}
            passHref={!!replace && !external}
            prefetch={prefetch}
            replace={replace && !external}
            aria-current="page"
            id={id}
            data-cy={dataCy}
            onClick={handleClick}
            target={external ? "_blank" : undefined}
            {...props}
        >
            {children}
        </Link>
    );
};

export default GTMLink;
