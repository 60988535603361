import { setCookie } from "cookies-next";

export const ARROW_CLASS =
    "transition-all delay-50 duration-300 w-auto h-auto ";
export const ARROW_ROTATE = " rotate-180 ";

export const COUNTRY_SELECTION_ROUTES = [
    "survey",
    "sa-how-it-works",
    "survey-complete",
];
