"use client";
import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { SessionProvider } from "next-auth/react";
import { useSession } from "next-auth/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { GoogleTagManager } from "@next/third-parties/google";
import { usePathname } from "next/navigation";
import { persistStore } from "redux-persist";
import { PrismicPreview } from "@prismicio/next";
import { PrismicProvider } from "@prismicio/react";
import { createWrapper } from "next-redux-wrapper";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { PersistGate } from "redux-persist/integration/react";
import store from "../store/store";
import { PinkBoxIcon } from "../public/Theme";
import { linkResolver, repositoryName } from "../prismicio";
import {
    ACCESS_TOKEN,
    USER_COUNTRY,
    SAUDI_ISO,
    DUBAI_ISO,
    ICON,
    PAGE_TITLE,
    PAGE_TITLES,
    PROMO_BANNER,
    FOOTER,
    AUTHENTICATED,
    UNAUTHENTICATED,
    GOOGLE_OPTIMIZE_JS_PATH,
    GTM_JS_PATH,
    HOTJAR_JS_PATH,
    PROMO_RESTRICTED_ROUTES,
    FOOTER_RESTRICTED_ROUTES,
} from "../public/Constants/EnumConstants";
import CustomToastContainer from "../components/Atoms/CustomToastContainer";
import { fetchUserData } from "../store/userSlice";
import Transition from "./Transition";
import { getCookie, setCookie } from "cookies-next";
import { resetAllSlices } from "../store/combinedResetActions";
import PromoBanner from "../components/Molecules/PromoBanner";
import Script from "next/script";
import "../styles/globals.css";

import "react-clock/dist/Clock.css";
import "slick-carousel/slick/slick.css";
import "react-calendar/dist/Calendar.css";
import "slick-carousel/slick/slick-theme.css";
import "react-time-picker/dist/TimePicker.css";
import "react-date-picker/dist/DatePicker.css";
import NewFooterSection from "../components/Molecules/NewFooterSection";
import Loading from "./loading";
import NewHeaderSection from "../components/Molecules/NewHeaderSection";
import { montserrat, playfair_display } from "./fonts";

const Whatsapp = dynamic(() => import("../components/Atoms/WhatsApp"));
export default function RootLayout(props) {
    //change this
    const persistor = persistStore(store);
    let pathName = usePathname();
    pathName = pathName?.split("/");
    const pageName = undefined;
    const pageNameConst = pageName
        ? pageName
        : PAGE_TITLES?.[pathName?.[pathName?.length - 2]];
    return (
        <html
            className={`${montserrat?.variable} ${playfair_display?.variable}`}
            lang="en"
        >
            <head>
                {pageNameConst && <title>{PAGE_TITLE + pageNameConst}</title>}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, viewport-fit=cover"
                />
                {/* <Script
          async
          strategy="beforeInteractive"
          id="gtm-script"
          src={GTM_JS_PATH}
        /> */}
                {/* <Script
          async
          strategy="beforeInteractive"
          src={GOOGLE_OPTIMIZE_JS_PATH}
        /> */}
             <Script
          async
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        /> 
                <Script async strategy="beforeInteractive">
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            });
        `}
                </Script>
                <Script
                    async
                    src="/JavaScript/newrelicagent.js"
                    strategy="beforeInteractive"
                />
                <Script
                    async
                    src={HOTJAR_JS_PATH}
                    strategy="beforeInteractive"
                ></Script>
                <Script
                    async
                    strategy="beforeInteractive"
                    src="https://cdn.checkout.com/js/framesv2.min.js"
                />
                <Script
                    async
                    strategy="afterInteractive"
                    src="https://widget.cloudinary.com/v2.0/global/all.js"
                    type="text/javascript"
                />
                <Script
                    async
                    strategy="afterInteractive"
                    src="https://lcx-embed-eu.bambuser.com/default/embed.js"
                />
                <Script
                    async
                    strategy="beforeInteractive"
                    src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACE_API_KEY}&loading=async&libraries=places`}
                    type="text/javascript"
                />
                <Script
                    async
                    strategy="beforeInteractive"
                    type="text/javascript"
                    src="https://smartarget.online/loader.js?u=d86add168ecd07a508bb7fa155526fdc751161f3"
                />
                <Script
                    async
                    id="hs-script-loader"
                    strategy="beforeInteractive"
                    type="text/javascript"
                    src="//js-eu1.hs-scripts.com/143182740.js"
                />

                <link
                    href="https://unpkg.com/video.js@7/dist/video-js.min.css"
                    rel="stylesheet"
                />
            </head>
            <body>
            <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MP3WW5N"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        ></noscript>
                <GoogleTagManager gtmId="GTM-MP3WW5N" />
                <SessionProvider>
                    <Provider store={store}>
                        <PersistGate
                            loading={<Loading />}
                            persistor={persistor}
                        >
                            <PrismicProvider
                                linkResolver={linkResolver}
                                internalLinkComponent={({ href, ...props }) => (
                                    <Link href={href} {...props}></Link>
                                )}
                            >
                                <PrismicPreview
                                    repositoryName={repositoryName}
                                />
                             <GoogleAnalytics trackPageViews />

                                <App>{props?.children}</App>
                                <CustomToastContainer />
                            </PrismicProvider>
                            {!pathName.includes("survey") && <Whatsapp />}
                        </PersistGate>
                    </Provider>
                </SessionProvider>
            </body>
        </html>
    );
}

const App = ({ children }) => {
    const { status } = useSession();
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state?.user);
    const { userCountry } = useSelector((state) => state?.country);
    const pathName = usePathname();

    useEffect(() => {
        if (status === AUTHENTICATED) {
            dispatch(fetchUserData());
        } else if (status === UNAUTHENTICATED) {
            userData !== null && dispatch(resetAllSlices());
        }
    }, [status]);

    useEffect(() => {
        if (pathName === "/" && userCountry?.flag === SAUDI_ISO) {
            window.location.href = process.env.HUBSPOT_KSA_WEB_URL;
        }
    }, [userCountry]);

    const isVisible = (section) => {
        if (section === PROMO_BANNER) {
            return !PROMO_RESTRICTED_ROUTES?.find((route) =>
                pathName?.includes(route)
            );
        } else if (section === FOOTER) {
            return !FOOTER_RESTRICTED_ROUTES?.find((route) =>
                pathName?.includes(route)
            );
        }
        return true;
    };

    return (
        <Suspense>
            <NewHeaderSection
                homeUrl={
                    userCountry?.flag === SAUDI_ISO
                        ? process.env.HUBSPOT_KSA_WEB_URL
                        : "/"
                }
            />
            {isVisible(PROMO_BANNER) && <PromoBanner />}
            <Transition>
                {" "}
                <Suspense>{children}</Suspense>{" "}
            </Transition>

            {isVisible(FOOTER) && <NewFooterSection homeUrl={"/"} />}
        </Suspense>
    );
};
const makeStore = () => store;
const wrapper = createWrapper(makeStore);

wrapper.withRedux(App);
