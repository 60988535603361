import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { setCookie } from "cookies-next";

const initialState = {
    userCountry: null,
};

const countrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        updateCountry: (state, { payload }) => {
            const { country, currency, flag, price } = payload || {};
            setCookie("country", JSON.stringify({ country, flag }));
            setCookie("currency", JSON.stringify({ currency, price }));
            state.userCountry = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, action) => {
            return {
                ...state,
                ...action?.payload?.country,
            };
        });
    },
});

export const { updateCountry } = countrySlice.actions;
export default countrySlice.reducer;
