import React from "react";
import WearthatLoader from "../components/Atoms/WearthatLoader";

const Loading = () => {
    return (
        <div>
            <WearthatLoader />
        </div>
    );
};

export default Loading;
