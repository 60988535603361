export const HIGHLIGHT_TEXT_CLASS = "text-Primary_Background font-bold";
export const PROMO_BANNER_CONTAINER =
    "bg-New_Primary_Default text-center py-2.5 md:py-4";
export const PROMO_TEXT_CONTAINER = "w-[90%] md:w-fit mx-auto";
export const BUTTON_CLASS =
    "inline-block text-[13px] text-Primary_Background md:!text-sm font-bold underline";
export const Montserrat_13PX_NORMAL =
    "text-[13px] font-semibold font-Montserrat text-Primary_Background text-center ";

export const ENGLISH_TEXT = {
    PROMO_TEXT: "Unlock ",
    PROMO_TEXT_2: " styled boxes for a  ",
    UNLIMITED: "unlimited",
    AED_150: "105 AED",
    ONE_OFF: " one-off ",
    GET_20: "GET20",
    BUTTON_TEXT: "Get Styled",
};

export const ARABIC_TEXT = {
    PROMO_TEXT: "  شحن وإرجاع مجاني على جميع الصناديق الخاصة بك! يحصل ",
    PROMO_TEXT_2: " من صندوقك الأول اليوم. ",
    AED_150: "150 درهم إماراتي",
    BUTTON_TEXT: "احصل على تنسيق ",
    GET_20: "GET20",
};

export const PROMO_BUTTON_ID_TEXT = "promo-banner-section-get_styled_button_id";
