import {
    Aboutus,
    ReferFriend,
    AmbassadorIcon,
    BlogsIcon,
    GiftCard,
    ShoppingBag,
    ShoppableSection,
} from "../../../public/Theme";
export const OPEN_MAIN_MENU = "Open main menu";
export const CLOSE_MENU_TEXT = "Close menu";
export const LOGO_ALT_TEXT = "Wearthat Logo";
export const LOGIN_ENDPOINT = "/login";
export const GIFT_CARD_ENDPOINT = "/gift-card/";
export const DRAWER_NAVIGATION = "drawer-navigation";
export const HEADER_LOGO_IMAGE_ID = "header_logo_img_id";
export const HEADER_BUTTON_ID = "header_button_id";
export const COUNTRY_ICON_ALT = "country-icon";
export const AR = "AR";
export const EN = "EN";
export const HEADER_LINK = [
    {
        link: `/about-us`,
        text: "About Us",
        src: Aboutus,
    },
    {
        link: `/gift-card/`,
        text: "Gift Card",
        src: GiftCard,
    },
    {
        link: `/referral`,
        text: "Refer A Friend",
        src: ReferFriend,
    },
    {
        link: `/ambassador-program`,
        text: "Ambassador Program",
        src: AmbassadorIcon,
    },
    {
        link: `/blogs`,
        text: "Blog",
        src: BlogsIcon,
    },
    {
        link: `${process.env.ECOM_SHOP_LINK}`,
        text: "Shop the Look",
        src: ShoppingBag,
    },
    {
        link: `${process.env.ECOM_SHOP_LINK}shoppable-sessions`,
        text: "Shoppable Sessions",
        src: ShoppableSection,
    },
];

export const LANGUAGE_BUTTON_TEXT = "Change Language ﺍﻟﹿﻌﹷﺮﹶﺑﻴﹽﺔ";
export const LANGUAGE_BUTTON_TEXT_ARABIC = "تغيير اللغة English";

export function slugify(string) {
    return string
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "_");
}

export const HEADER_GIFT_CARD_BUTTON_ID = "header_gift_card_button_id";
export const HEADER_LOGIN_BUTTON_ID = "header_login_button_id";
export const CHANGE_LANGUAGE = "change_language";
export const MENU_ICON_ALT = "Menu Icon";
export const CLOSE_ICON_ALT = "close Icon";
export const LAZY = "lazy";
