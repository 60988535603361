// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setCookie } from "cookies-next";
import { HYDRATE } from "next-redux-wrapper";
import { forgotPasswordApi } from "../app/api/forgotPasswordApi";
import {
    AUTH_FACEBOOK_LOGIN,
    AUTH_FORGOT_PASSWORD,
    AUTH_LOGIN,
    AUTH_SIGNUP,
    SUCCESS_MESSAGE,
} from "./types";
import { fetchUserData } from "./userSlice";
import { getUserSurvey, resetSurvey } from "./surveySlice";
import { toast } from "react-toastify";
import { ACCESS_TOKEN } from "../public/Constants/EnumConstants";

export const forgotPassword = createAsyncThunk(
    AUTH_FORGOT_PASSWORD,
    async (email, { rejectWithValue }) => {
        const response = await forgotPasswordApi(email);
        if (response?.message === SUCCESS_MESSAGE) {
            return response?.message;
        }
        return rejectWithValue(response?.message);
    }
);

// Slice for authentication and user data
const authSlice = createSlice({
    name: "auth",
    initialState: {
        accessToken: null,
        isLoading: false,
        error: null,
        forgotPasswordSuccess: null,
    },
    reducers: {
        resetState: (state, action) => {
            const { payload } = action;
            switch (payload?.type) {
                case "error":
                    state.error = null;
                case "forgotPasswordSuccess":
                    state.forgotPasswordSuccess = null;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => {
                return {
                    ...state,
                    ...action?.payload?.auth,
                };
            })
            .addCase(forgotPassword?.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.success = null;
            })
            .addCase(forgotPassword?.fulfilled, (state, action) => {
                state.isLoading = false;
                state.forgotPasswordSuccess = action?.payload;
                state.error = null;
            })
            .addCase(forgotPassword?.rejected, (state, action) => {
                state.isLoading = false;
                state.forgotPasswordSuccess = null;
                state.error = action?.payload;
            });
    },
});

export const { resetState } = authSlice?.actions;
export default authSlice?.reducer;
