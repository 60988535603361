const GoogleTagManagerEvents = {
    navigationInteractions: "NavigationInteractions",
    formInteractions: "FormInteractions",
    surveyInteractions: "SurveyInteractions",
    userProfileEvent: "UserProfileInteractions",
    clickInteractions: "ClickInteractions",
    pageNotFound: "UnknownPageError",
};

export default GoogleTagManagerEvents;
