import {
    ARABIC,
    ENGLISH,
    SIGN_UP_ENDPOINT,
    ORDER_A_LOOK,
} from "../../../public/Constants/EnumConstants";

import InstagramIcon from "../../Atoms/SVGs/InstagramIcon";
import PinterestIcon from "../../Atoms/SVGs/PinterestIcon";
import TikTokIcon from "../../Atoms/SVGs/TikTokIcon";
import WhatsAppIcon from "../../Atoms/SVGs/WhatsAppIcon";
import { WHATSAPP_HUBSPOT, WHATSAPP_URL } from "../../Atoms/WhatsApp/constants";

export const MAIN_CONTAINER_CLASS =
    "p-3 sm:px-12 xl:px-7 2xl:px-12 sm:py-12 sm:pb-7 bg-New_Secondary_Background";
export const CONTAINER_SIZE_CLASS = "max-w-7xl mx-auto ";
export const NEWSLETTER_SECTION_WRAPPER = "lg:flex justify-center";
export const NEWSLETTER_SECTION_CONTAINER = "lg:w-[596px]";
export const NEWSLETTER_HEADING_CLASS =
    "text-New_Primary_Default font-Montserrat text-[13px] sm:text-base my-2 ";
export const NEWSLETTER_FOOTER_CLASS =
    "text-gray-500 font-Montserrat text-[10px] sm:text-xs	mt-2";
export const NEWS_LETTER_TERMS_CTA_CLASS = "underline decoration-gray-400";
export const COPYRIGHT_CLASS =
    "flex flex-col justify-between items-center h-12 my-4";
export const POLICY_TERM_CONTAINER =
    "flex w-60	sm:w-72	 justify-between items-center";
export const POLICY_TERM_CLASS = "text-xs sm:text-sm	font-semibold underline";
export const FOOTER_COPYRIGHT_CLASS =
    "text-xs sm:text-sm font-normal text-gray-400";
export const AR = "AR";
export const EN = "EN";
export const PRIVACY_POLICY_LINK = `/documents/privacy-policy`;
export const TERMS_AND_CONDITIONS_LINK = "/documents/terms-and-conditions/";

export const NEWS_LETTER_HEADER_TEXT = "Sign up for updates";
export const NEWS_LETTER_FOOTER_TEXT = "By signing up you agree to our ";
export const NEWS_LETTER_TERMS_CTA = "terms";
export const NEWS_LETTER_TERMS_DOT = ".";
export const PRIVICY_POLICY = "Privacy Policy";
export const TERMS_OF_USE = "Terms of Use";
const date = new Date();
export const COPYRIGHT_TEXT = `© Copyright ${date?.getFullYear()}. All rights reserved.`;

export const FOOTER_LINKS = [
    {
        section: "Company",
        links: [
            { title: "About Us", href: `/about-us` },
            { title: "Journal", href: `/blogs` },
            { title: "Gift Card", href: `/gift-card` },
        ],
    },
    {
        section: "My Account",
        links: [
            {
                title: "Take the Style Quiz",
                href: `/survey-register`,
            },
            { title: "Login", href: `/login` },
            {
                title: "My Boxes",
                href: `/dashboard/myBoxes`,
            },
        ],
    },
    {
        section: "Shop The Look",
        links: [
            {
                title: "Pre-Styled Boxes",
                href: `${process?.env?.ECOM_SHOP_LINK}products?collection=boxes`,
            },
            {
                title: "Basma Collection",
                href: `${process?.env?.ECOM_SHOP_LINK}products?collection=100`,
            },
            {
                title: "Tamara Collection",
                href: `${process?.env?.ECOM_SHOP_LINK}products?category=106`,
            },
        ],
    },
    {
        section: "More From Wear That",
        links: [
            {
                title: "Refer A Friend",
                href: `/referral`,
            },
            {
                title: "Ambassador Program",
                href: `/ambassador-program`,
            },
            { title: "Faq", href: `/faqs` },
        ],
    },
];
export const SOCIAL_MEDIA_LINKS = [
    {
        id: "whatsapp-icon",
        link: WHATSAPP_URL + WHATSAPP_HUBSPOT,
        Icon: <WhatsAppIcon alt="whatsapp-icon" />,
        className: "text-gray-700 hover:text-gray-900",
    },
    {
        id: "instagram-icon",
        link: "https://www.instagram.com/wear.that.now/",
        Icon: <InstagramIcon alt="instagram-icon" />,
        className: "text-gray-700 hover:text-gray-900",
    },
    {
        id: "tiktok-icon",
        link: "https://www.tiktok.com/@wearthat",
        Icon: <TikTokIcon alt="tiktok-icon" />,
        className: "text-gray-700 hover:text-gray-900 ",
    },
    {
        id: "pintrest-icon",
        link: "https://www.pinterest.com/wearthatnow/",
        Icon: <PinterestIcon alt="pintrest-icon" />,
        className: "text-gray-700 hover:text-gray-900 ",
    },
];

export function slugify(string) {
    return string
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "_");
}

export const _BUTTON_FOOTER_ID = "_button_footer_id;";
