import { createSlice } from "@reduxjs/toolkit";

const homePopupSlice = createSlice({
    name: "popup",
    initialState: {
        display: "none",
        loaded: false,
    },
    reducers: {
        setVisibility: (state, action) => {
            state.display = action?.payload;
        },
        setLoad: (state, action) => {
            state.loaded = action?.payload;
        },
    },
});

export const { setVisibility, setLoad } = homePopupSlice?.actions;
export default homePopupSlice?.reducer;
