"use client";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import Image from "next/image";
import { useSearchParams, usePathname } from "next/navigation";
import { getCookie } from "cookies-next";
import {
    ARROW_CLASS,
    ARROW_ROTATE,
    COUNTRY_SELECTION_ROUTES,
} from "./constants.js";
import { DownArrow } from "../../../public/Theme";
import { CircleFlag } from "react-circle-flags";
import {
    countriesWithRate,
    getCountryDetails,
} from "../../../public/Constants/CountriesList";
import {
    USER_COUNTRY,
    USER_CURRENCY,
} from "../../../public/Constants/EnumConstants";
const DropDown = dynamic(() => import("./DropDown"));
import { updateCountry } from "../../../store/countrySlice";

const CurrencySelectorDropdown = () => {
    const searchParams = useSearchParams();
    const pathName = usePathname();
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state?.user);
    const isSaudia = searchParams.get("sa");

    const [value, setValue] = useState(false);
    const [visible, setVisible] = useState(false);

    // Function to handle keydown events for keyboard accessibility
    const handleKeyDown = (event) => {
        if (event?.key === "Enter" || event?.key === " ") {
            setValue((prev) => !prev);
        } else if (event?.key === "Escape") {
            setValue(false);
        }
    };

    useEffect(() => {
        if (userData && userData?.payment_status === "paid") {
            setVisible(false);
        } else {
            const vibisility = !COUNTRY_SELECTION_ROUTES?.find(
                (route) => pathName.split("/")?.[1] === route
            );
            setVisible(vibisility);
        }
    }, [pathName, userData]);

    useEffect(() => {
        const setCountry = (country_code) => {
            const filterData = countriesWithRate()?.find(
                (country) => country?.iso_alpha_2 === country_code
            );
            dispatch(
                updateCountry({
                    country: filterData?.country,
                    flag: filterData?.iso_alpha_2,
                    currency: filterData?.code,
                    price: filterData?.subscription_rate,
                })
            );
        };
        const getUserLocation = async () => {
            if (!getCountryDetails()) {
                let result = {
                    country_name: "United Arab Emirates",
                    country_code: "AE",
                };
                try {
                    const response = await fetch(
                        `${process.env.BACKEND_URL}api/v1/getLocation/?key=${process.env.IP2LOCATION_API_KEY}`
                    );
                    if (response.ok) {
                        result = await response.json();
                    }
                } catch (e) {
                    console.log(e);
                }
                let { country_name, country_code } = result;
                setCountry(country_code);
                setValue(false);
            } else {
                setCountry(getCountryDetails()?.flag);
            }
        };
        if (userData && userData?.detected_signup_country) {
            setCountry(userData?.detected_signup_country);
        } else if (isSaudia) {
            setCountry("SA");
        } else {
            getUserLocation();
        }
    }, [userData]);

    return (
        <div className="relative w-[60px]">
            <button
                className="border border-solid border-white cursor-pointer flex gap-x-2 items-center text-xs lg:text-sm   font-medium  h-8 lg:h-10 min-w-fit"
                onClick={() => setValue((prev) => !prev)}
                disabled={!visible}
                onKeyDown={handleKeyDown}
                aria-expanded={value}
                aria-controls="currency-dropdown"
            >
                <CircleFlag
                    countryCode={getCountryDetails()?.flag?.toLowerCase()}
                    alt="Flag"
                    className="w-full h-full"
                />
                {visible && (
                    <Image
                        width={0}
                        height={0}
                        className={ARROW_CLASS + (value ? ARROW_ROTATE : "")}
                        src={DownArrow}
                        alt="Chevron"
                    />
                )}
            </button>
            {value && <DropDown value={value} setValue={setValue} />}
        </div>
    );
};

export default CurrencySelectorDropdown;
