import {
    ClipboardIcon,
    FailedIcon,
    SuccessTickPink,
    TickInCircleGreen,
} from "../../../public/Theme";
export const TOAST_TIME = 5000;
export const contextClass = {
    success: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-4",
    error: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-4",
    info: "md:min-w-max md:pr-20 !right-0 !left-0 !mx-4",
    warning: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-4",
    default: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-4",
};

export const contextIcons = {
    success: TickInCircleGreen,
    error: FailedIcon,
    info: ClipboardIcon,
    warning: SuccessTickPink,
    default: SuccessTickPink,
};
